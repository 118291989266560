.table-head-content {
    padding-left: 5px;
    font-weight: bold;
}

.card label {
    font-size: 0.95em;
    color: #414141;
    font-weight: bold;

}

.block {
    background-color: #ffffff;
    padding: 20px;
    margin-top: 10px;
}

.block .sub-head {
    color: #353535;
    font-size: 120%;
    font-weight: 600;
    margin-bottom: 0px;
}

.show-updated-text {
    background-color: black;
    color: #cecece;
    font-size: 80%;
    font-weight: 500;
    padding: 3px;
}

.selected-row {
    background-color: #4a4ccc44 !important;
}

.explanation {
    display: inline-block;
    /* the default for span */
    width: 150px;
    background-color: #dfdfdf;
    padding: 5px;

}

.car-card {
    background-color: transparent !important;
    border-radius: 0px;
    box-shadow: none !important;
    margin-bottom: 0 !important;
}

.list-image {
    /* width: 175px; */
    height: 150px;
}

.no-bottom-magin {
    margin-bottom: 0 !important;
}
.load_employee{
    margin:230px;
    margin-left:290px !important;
}