.block-div{
    background-color: #f8f8f8;
    padding: 10px;
margin-top: 10px;
}
.block-div p{
    color: #353535;
    font-size: 100%;
    font-weight: 600;
    margin-bottom: 0px;
}

.block-div label{
    color: #202020;
    font-size: 110%;
    font-weight: 600;
    margin-bottom: 0px;
}


.text-changed{
    border: rgb(127, 129, 255) 2px solid;
    padding: 5px;
    opacity: 1;
    
    animation: fadeIn 5s;
    /* animation-delay: 5s; */
    animation-fill-mode: forwards;
   
}
.show-updated-text {
    color: #ffa500;
    font-size: 80%;
    font-weight: 600;
}

@keyframes fadeIn {
    from { opacity: .6; }
    to { opacity: 1; }
}