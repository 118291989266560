.navbar{
    padding-top: $navbar-padding-base;
    padding-bottom: $navbar-padding-base;
    min-height: 53px;
    margin-bottom: 20px;

    a{
        vertical-align: middle;

        &:not(.btn):not(.dropdown-item){
            color: $white-color;
        }

        &.dropdown-item{
            color: $default-color;
        }
    }



    &.bg-white{
      .input-group .form-control,
      .input-group.no-border .form-control{
        color: $default-color;

        @include placeholder(){
          color: $default-color;
        };
      }
      .input-group-prepend .input-group-text i,
      .input-group-append .input-group-text i{
        color: $default-color;
        opacity: .5;
      }
    }

    .form-group,
    .input-group{
      margin: 0;
      margin-left: -3px;
      margin-right: 5px;

      .form-group-addon,
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text{
        color: $default-color;

        i {
          opacity: 1;
        }
      }

      &.no-border{
        .form-control{
          color: $default-color;

          @include placeholder(){
            color: $default-color;
          };
        }
      }
    }

    p{
        display: inline-block;
        margin: 0;
        line-height: 1.8em;
        font-size: 1em;
        font-weight: 400;
    }

    &.navbar-absolute{
        position: absolute;
        width: 100%;
        padding-top: 10px;
        z-index: 1029;
    }

    .documentation &{
        &.fixed-top{
            left: 0;
            width: initial;
        }
    }

    .navbar-wrapper{
        display: inline-flex;
        align-items: center;

        .navbar-minimize{
            padding-right: 10px;

            .btn{
                margin: 0;
            }
        }

        .navbar-toggle{
            .navbar-toggler{
                padding-left: 0;
            }

            &:hover{
                & .navbar-toggler-bar.bar2{
                    width: 22px;
                }
            }
        }
    }



    .navbar-nav{
        &.navbar-logo{
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 49px;
            top: -4px;
        }

        .nav-link.btn{
            padding: $padding-btn-vertical $padding-btn-horizontal;
            &.btn-lg{
                padding: $padding-large-vertical $padding-large-horizontal;
            }
            &.btn-sm{
                padding: $padding-small-vertical $padding-small-horizontal;
            }
        }

        .nav-link{
            text-transform: uppercase;
            font-size: $font-size-mini;
            padding: $padding-base-vertical $padding-base-horizontal;
            line-height: $line-height-nav-link;
            margin-right: 3px;

            i.fa + p,
            i.nc-icon + p{
                margin-left: 3px;
            }

            i.fa,
            i.nc-icon{
                font-size: 18px;
                position: relative;
                top: 3px;
                text-align: center;
                width: 21px;
            }

            i.nc-icon{
                top: 4px;
                font-size: 16px;
            }

            &.profile-photo{
                .profile-photo-small{
                    width: 27px;
                    height: 27px;
                }
            }

            &.disabled{
                opacity: .5;
                color: $white-color;
            }
        }

        .nav-item.active .nav-link:not(.btn),
        .nav-item .nav-link:not(.btn):focus,
        .nav-item .nav-link:not(.btn):hover,
        .nav-item .nav-link:not(.btn):active{
            border-radius: $border-radius-small;
            color: $default-color;
        }
    }

    .logo-container{
        width: 27px;
        height: 27px;
        overflow: hidden;
        margin: 0 auto;
        border-radius: 50%;
        border: 1px solid transparent;
    }

    .navbar-brand{
        text-transform: capitalize;
        font-size: $font-size-large-navbar;
        padding-top: $padding-base-vertical;
        padding-bottom: $padding-base-vertical;
        line-height: $line-height-nav-link;
    }

    .navbar-toggler{
        width: 37px;
        height: 27px;
        vertical-align: middle;
        outline: 0;
        cursor: pointer;

        & .navbar-toggler-bar.navbar-kebab{
            width: 3px;
            height: 3px;
            border-radius: 50%;
            margin: 0 auto;
        }
    }

    .button-dropdown{
        .navbar-toggler-bar:nth-child(2){
            width: 17px;
        }
    }

    &.navbar-transparent{
      background-color: $transparent-bg !important;
      box-shadow: none;
      border-bottom: 1px solid #ddd;

      a:not(.dropdown-item):not(.btn){
        color: $default-color;

        &.disabled{
          opacity: .5;
          color: $default-color;
         }
       }

       .button-bar{
           background: $default-color;
       }

      .nav-item .nav-link:not(.btn){
        color: $default-color;
      }
      .nav-item.active .nav-link:not(.btn),
      .nav-item .nav-link:not(.btn):focus,
      .nav-item .nav-link:not(.btn):hover,
      .nav-item .nav-link:not(.btn):focus:hover,
      .nav-item .nav-link:not(.btn):active {
        color: $primary-color;
      }
    }

    &.bg-white {
        a:not(.dropdown-item):not(.btn){
            color: $default-color;

            &.disabled{
                opacity: .5;
                color: $default-color;
            }
        }

        .button-bar{
            background: $default-color;
        }

        .nav-item.active .nav-link:not(.btn),
        .nav-item .nav-link:not(.btn):focus,
        .nav-item .nav-link:not(.btn):hover,
        .nav-item .nav-link:not(.btn):active{
            color: $info-color;
        }
     

        .logo-container{
            border: 1px solid $default-color;
        }
    }

    .navbar-collapse {
      .nav-item {
        a {
          font-size: $font-size-base;
        }
      }
    }
}

.bg-default{
    background-color: $default-color !important;
}

.bg-primary{
    background-color: $primary-color !important;
}

.bg-info{
    background-color: $info-color !important;
}

.bg-success{
    background-color: $success-color !important;
}

.bg-danger{
    background-color: $danger-color !important;
}

.bg-warning{
    background-color: $warning-color !important;
}

.bg-white{
    background-color: $white-color !important;
}

.navbar {
    font-weight: $font-weight-light;
    background: $navbar-bg;
    transition:background $action-transition-duration $action-transition-timing-function;
    -webkit-transition:background $action-transition-duration $action-transition-timing-function;
    -moz-transition:background $action-transition-duration $action-transition-timing-function;
    -ms-transition:background $action-transition-duration $action-transition-timing-function;
  
    .navbar-brand {
      width: 110px;
    }
    .navbar-brand-wrapper {
      transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      background: $navbar-bg;
      width: $sidebar-width-lg;
      height: $navbar-height;
      :host-context(.sidebar-dark) & {
        background: $sidebar-dark-bg;
      }
  
      @media (max-width: 991px) {
        width: 55px;
      }
      .navbar-brand {
         color: lighten(#e4dcdc, 20%);
        font-size: 1.5rem;
        line-height: 48px;
        margin-right: 0;
        padding: .25rem 0;
        width: 100%;
  
        &:active,
        &:focus,
        &:hover {
          color: lighten(#e4dcdc, 10%);
        }
  
        img {
          width: calc(#{$sidebar-width-lg} - 120px );
          max-width: 100%;
          // height: 28px;
          margin: auto;
          vertical-align: middle;
        }
        &.brand-logo-mini {
          display: none;
          img {
            width: calc(#{$sidebar-width-icon} - 50px );
            max-width: 100%;
            // height: 28px;
            margin: auto;
          }
        }
      }
    }
    .navbar-menu-wrapper {
      transition: width $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function;
      color: $navbar-menu-color;
      padding-left: 24px;
      padding-right: 24px;
      width: calc(100% - #{$sidebar-width-lg});
      height: $navbar-height;
      @media (max-width: 991px) {
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
      }
  
      .navbar-toggler {
        border: 0;
        color: inherit;
        height: $navbar-height;
      border-radius: 0px;
        padding-left: 5px;
        padding-right: 20px;
        &:not(.navbar-toggler-right) {
          font-size: 1.5rem;
          @media (max-width: 991px) {
            display: none;
          }
        }
        &.navbar-toggler-right{
          @media (max-width:991px){
            padding-left: 15px;
            padding-right: 45px;
            border-right: none;
          }
        }
      }
  
      .search-field {
        .input-group {
          input {
            font-size: $default-font-size;
            padding: .5rem;
            // @include input-placeholder {
            //   font-size: $navbar-font-size;
            //   color: $navbar-menu-color;
            //   font-weight: $font-weight-light;
            // }
          }
          i {
            font-size: 17px;
            margin-right: 0;
            color: $navbar-menu-color;
          }
          .input-group-text {
            background: transparent;
          }
        }
        // .rtl & {
        //   @extend .ml-0;
        //   @extend .mr-4;
        // }
      }
  
      .count-indicator {
        position: relative;
  
        .count-symbol,
        .count-number {
          position: absolute;
          border-radius: 100%;
        }
        .count-symbol {
          top: 18px;
          right: 0px;
          width: 7px;
          height: 7px;
        }
        .count-number {
          min-width: 14px;
          height: 14px;
          font-size: .5rem;
          color: $white;
          bottom: 16px;
          right: -5px;
          line-height: 1;
          text-align: center;
        }
        &:after {
          display: none;
        }
      }
      .navbar-nav {
        flex-direction: row;
        align-items: center;
        .nav-item {
          .nav-link {
            color: inherit;
            font-size: $navbar-font-size;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            height: $navbar-height;
           display: flex;
            align-items: center;
            cursor: pointer;
            @media (max-width: 767px) {
              margin-left: .8rem;
              margin-right: .8rem;
            }
            i {
              font-size: $navbar-icon-font-size;
            }
          }
          .dropdown {
            .dropdown-toggle {
              border: 0;
              padding: 0;
              background: transparent;
              font-weight: $font-weight-light;
              &:focus {
                box-shadow: none;
              }
              &:after {
                color: theme-color(primary);
                font-size: 1rem;
              }
            }
            .dropdown-menu {
            //   @extend .dropdownAnimation;
              margin-top: 0;
              border: none;
           border-radius: 5px;
              -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
              -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
              box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
              &.navbar-dropdown {
                .rtl & {
                  right: auto;
                  left: 0;
                }
  
                .dropdown-item {
                //   @extend .d-flex;
                //   @extend .align-items-center;
                display: flex;
                align-items: center;
                  margin-bottom: 0;
                  padding: 11px 13px;
                  cursor: pointer;
  
                  i {
                    font-size: 17px;
                  }
  
                  .ellipsis {
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                //   .rtl & {
                //     i{
                //       @extend .mr-0;
                //       margin-left: 10px;
                //     }
                //   }
                }
  
                .dropdown-divider {
                  margin: 0;
                }
              }
            }
            @media (max-width: 767px) {
              position: static;
              .navbar-dropdown {
                top: $navbar-height;
                width: 100%;
              }
            }
          }
          &.nav-settings {
            // padding-left: 20px;
            padding-right: 5px;
            .nav-link{
              margin-right: 0;
              margin-left: 0;
              background-color: transparent;
            }
          }
          &.nav-profile {
            .nav-link {
            //   @extend .d-flex;
            display: flex;
              .nav-profile-img {
                position: relative;
                width: 32px;
                height: 32px;
                img {
                  width: 32px;
                  height: 32px;
                  border-radius: 100%;
                }
                .availability-status {
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  border-radius: 100%;
                  border: 2px solid #ffffff;
                  bottom: 5px;
                  right: -5px;
                  &.online {
                    background: theme-color(success);
                  }
                  &.offline {
                    background: theme-color(danger);
                  }
                  &.busy {
                    background: theme-color(warning);
                  }
                }
              }
              .nav-profile-text {
                margin-left: 1.25rem;
                .rtl & {
                  margin-left: 0;
                  margin-right: 1.25rem;
                }
                p {
                  line-height: 1;
                }
                @media (max-width: 767px) {
                  display: none;
                }
              }
              &.dropdown-toggle {
                &:after {
                  line-height: 2;
                }
              }
            }
          }
        }
        &.navbar-nav-right {
          @media (min-width: 992px) {
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }
    }
  }
  @media (max-width:991px) {
    .navbar {
      flex-direction: row;
      .navbar-brand-wrapper {
        width: 200px;
        .navbar-brand {
          // &.brand-logo {
          //   display: none;
          // }
          &.brand-logo-mini {
            display: inline-block;
          }
        }
      }
    }
  
    .navbar-collapse {
      display: flex;
      margin-top: 0.5rem;
    }
  }
  
  @media (max-width:480px) {
    .navbar {
      .navbar-brand-wrapper {
        width: 55px;
        .brand-logo-mini{
          padding-top: 0px;
        }
      }
    }
  }
  
  /* Navbar color variations */
  @each $color, $value in $theme-gradient-colors {
    .navbar {
      &.navbar-#{$color} {
        .navbar-menu-wrapper {
          background: $value;
          color: $white;
          .nav-profile-text {
            p {
            //   @extend .text-white;  
            color: #fff;       
            }
          }
          .search-field {
            .input-group {
            //   input {
            //     @include input-placeholder {
            //       color: $white;
            //     }
            //   }
              i {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
  .nav li i {
    color : #000 !important;
  }

  .dropdown-toggle[aria-expanded="true"]:after,
a[data-toggle="collapse"][aria-expanded="true"] .caret,
.card-collapse .card a[data-toggle="collapse"][aria-expanded="true"] i,
.card-collapse .card a[data-toggle="collapse"].expanded i {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(0deg) !important; }

  .sidebar .nav .caret,
    .off-canvas-sidebar .nav .caret {
      
    transform: rotate(90deg) !important; }

    .btn-nav {
background: NONE;
color: #000 !important;
    }
    .btn-nav:hover {
      color: #000 !important;
          }

    #minimizeSidebar:active {
      background-color:transparent !important;
    }
    #minimizeSidebar:hover {
      background-color:transparent !important;
    }
    #minimizeSidebar:focus {
      background-color:transparent !important;
      color: #000 !important;
    }
    #minimizeSidebar.btn:active {
      background-color: transparent !important;
      color: #66615B !important;
  }

    @import "~@mdi/font/scss/materialdesignicons";

    .nav-link dropdown-toggle:hover{
      color: #000 !important;

    }

    button.nav-link.dropdown-toggle.btn.btn-primary:hover {
      color: #000 !important;
  }
  button.nav-link.dropdown-toggle.btn.btn-primary:active {
    color: #000 !important;
}
button.nav-link.dropdown-toggle.btn.btn-primary:focus {
  color: #000 !important;
}

.logout-btn {
  font-size: 20px !important;
}

.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus {
  background-color: transparent !important;
}

.dropdown-item a {
  // background-color: red !important;
  color: #6bd098 !important;
  padding-left: 20px;
  text-decoration: none !important;
}
.btn-primary:hover{
  background-color: #51cbce !important;
}


button.nav-link.dropdown-toggle.btn.btn-primary:hover {
  background: transparent !important;
}