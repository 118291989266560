.block-div{
    background-color: #f8f8f8;
    padding: 10px;
margin-top: 10px;
}
.block-div .lead{
    color: #353535;
    font-size: 120%;
    font-weight: 600;
    margin-bottom: 0px;
}

.large-phone-number{
    color: #353535;
    font-size: 150%;
    font-weight: 600;
}
.no-phone-number{
    color: #e70606;
    font-size: 150%;
    font-weight: 600;
}

.block-div .row {
    margin-bottom: 5px;

  }
.lead-stage-explain p {
    background-color: #353535;
    color: #ffffff;
    font-size: 90%;
    font-weight: 500;
    margin-top: -10px;
    padding: 5px;
    
}

.lead-text-area {
    min-width:500px;
    max-width: 100%;
    min-height:100px;
    height: 100%;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    /* border-width: 0.5px;*/ 
    border-color: #353535; 
    border:1px solid #353535;
    /* box-shadow: 0 0 10px #719ECE; */
  }

  .left-shift{
      margin-left: 15px;
      color: #a3a3a3;
  }

  .left-shift p{
    line-height: 3%;
    font-weight: 400;
    
}