//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$font-color:                 #66615b !default;
$fill-font-color:            rgba(255, 255, 255, 0.8);
$font-family-sans-serif:    'Be Vietnam', serif, 'Montserrat', "Helvetica", Arial, sans-serif;
$sans-serif-family:         'Be Vietnam', serif, 'Montserrat', 'Helvetica Neue',  Arial, sans-serif;


$none:                       0   !default;
$border-thin:                1px !default;
$border-thick:               2px !default;

$white-color:                #FFFFFF !default;
$white-bg:                   #FFFFFF !default;
$orange-bg:                  #e95e38 !default;

$smoke-bg:                   #F5F5F5 !default;
$light-black:                #444    !default;

$black-bg:                   rgba(30,30,30,.97) !default;

$black-color:                #2c2c2c !default;
$black-hr:                   #444444 !default;

$hr-line:                    rgba(0,0,0, .1) !default;

$light-gray:                 #E3E3E3 !default;
$medium-gray:                #DDDDDD !default;
$dark-gray:                  #9A9A9A !default;

$table-line-color:           #ccc !default;
$muted-color:                #a49e93 !default;

$opacity-gray-3:             rgba(222,222,222, .3) !default;
$opacity-gray-5:             rgba(222,222,222, .5) !default;
$opacity-gray-8:             rgba(222,222,222, .8) !default;


$opacity-5:                  rgba(255,255,255, .5) !default;
$opacity-8:                  rgba(255,255,255, .8) !default;

$datepicker-color-days:             rgba(255,255,255, .8)  !default;
$datepicker-color-old-new-days:     rgba(255,255,255, .4)  !default;


$opacity-1:                  rgba(255,255,255, .1) !default;
$opacity-2:                  rgba(255,255,255, .2) !default;

$transparent-bg:             transparent !default;
$dark-background:            #555555 !default;

$gray-input-bg:              #fffcf5 !default;
$danger-input-bg:            #FFC0A4 !default;
$success-input-bg:           #ABF3CB !default;
$other-medium-gray:          #A49E93 !default;
$transparent-bg:             transparent !default;

$placeholder-gray:           rgba(210, 210, 210, 1)  !default;

$default-color:              #a2a2a2 !default;
$default-bg:                 #66615B !default;
$default-states-color:       #403D39 !default;

$smoke-bg:                   #F5F5F5 !default;
$pale-bg:                    #FFFCF5 !default;
$medium-pale-bg:             #F1EAE0 !default;
$default-body-bg:                 #f4f3ef !default;

$primary-color:              #51cbce !default;
$primary-states-color:       darken($primary-color, 10%) !default;

$success-color:              #6bd098 !default;
$success-states-color:       darken($success-color, 10%) !default;

$info-color:                 #51bcda !default;
$info-states-color:          darken($info-color, 10%) !default;

$warning-color:              #fbc658 !default;
$warning-states-color:       darken($warning-color, 10%) !default;

$danger-color:               #ef8157 !default;
$danger-states-color:        darken($danger-color, 8%) !default;

$link-disabled-color:        #666666 !default;

$purple-color:               #c178c1 !default;
$purple-states-color:        darken($purple-color, 8%) !default;

$medium-pale-bg:             #F1EAE0 !default;

$brown-color:                #dcb285 !default;
$default-color-opacity:      rgba(182, 182, 182, .6) !default;
$primary-color-opacity:      rgba(249, 99, 50, .3) !default;
$success-color-opacity:      rgba(24, 206, 15, .3) !default;
$info-color-opacity:         rgba(44, 168, 255, .3) !default;
$warning-color-opacity:      rgba(255, 178, 54, .3) !default;
$danger-color-opacity:       rgba(255, 54, 54, .3) !default;

$new-blue:            #1DC7EA;
$new-purple:          #9368E9;
$new-red:             #FB404B;
$new-green:           #87CB16;
$new-orange:          #FFA534;
$new-dark-blue:       #1F77D0;
$new-black:           #5e5e5e;

$orange-color:               #f96332 !default;
$color-red-error: rgb(185, 74, 72) !default;
$color-grey-arrow: rgba(204, 204, 204, 0.2) !default;

$bg-nude:               #FFFCF5 !default;
$bg-primary:            lighten($primary-color, 7%) !default;
$bg-info:               lighten($info-color, 7%) !default;
$bg-success:            lighten($success-color, 7%) !default;
$bg-warning:            lighten($warning-color, 7%) !default;
$bg-danger:             lighten($danger-color, 7%) !default;
$bg-brown:              lighten($brown-color, 7%) !default;
$bg-purple:             lighten($purple-color, 7%) !default;

//     brand Colors
$brand-primary:              $primary-color !default;
$brand-info:                 $info-color !default;
$brand-success:              $success-color !default;
$brand-warning:              $warning-color !default;
$brand-danger:               $danger-color !default;
$brand-inverse:              $black-color !default;

$link-disabled-color:        #666666 !default;
$dark-color:                 #212120 !default;
//     light colors
$light-blue:                 rgba($primary-color, .2);
$light-azure:                rgba($info-color, .2);
$light-green:                rgba($success-color, .2);
$light-orange:               rgba($warning-color, .2);
$light-red:                  rgba($danger-color, .2);

// padding for links inside dropdown menu
$padding-dropdown-vertical:     10px !default;
$padding-dropdown-horizontal:   15px !default;

$margin-large-vertical:        30px !default;
$margin-base-vertical:         15px !default;

// border radius for buttons
$border-radius-none:             0px !default;
$border-radius-btn-small:      26px !default;
$border-radius-btn-base:       20px !default;
$border-radius-btn-large:      50px !default;


$margin-bottom:                0 0 10px 0 !default;
$border-radius-small:           3px !default;
$border-radius-base:            4px !default;
$border-radius-large:           6px !default;
$border-radius-x-large:         8px !default;
$border-radius-extreme:         12px !default;

//variables used in cards
$card-black-color:          #252422 !default;
$card-muted-color:          #ccc5b9 !default;

$card-background-blue:      #b8d8d8 !default;
$card-font-blue:            #506568 !default;
$card-subtitle-blue:        #7a9e9f !default;

$card-background-green:      #d5e5a3 !default;
$card-font-green:            #60773d !default;
$card-subtitle-green:        #92ac56 !default;

$card-background-yellow:      #ffe28c !default;
$card-font-yellow:            #b25825 !default;
$card-subtitle-yellow:        #d88715 !default;

$card-background-brown:      #d6c1ab !default;
$card-font-brown:            #75442e !default;
$card-subtitle-brown:        #a47e65 !default;

$card-background-purple:      #baa9ba !default;
$card-font-purple:            #3a283d !default;
$card-subtitle-purple:        #5a283d !default;

$card-background-orange:      #ff8f5e !default;
$card-font-orange:            #772510 !default;
$card-subtitle-orange:        #e95e37 !default;



//== Components
//

$padding-input-vertical:        11px !default;
$padding-input-horizontal:      11px !default;

$padding-btn-vertical:         11px !default;
$padding-btn-horizontal:       22px !default;

$padding-base-vertical:        .5rem !default;
$padding-base-horizontal:      .7rem !default;

$padding-round-horizontal:     23px !default;

$padding-simple-vertical:      10px !default;
$padding-simple-horizontal:    17px !default;

$padding-large-vertical:       15px !default;
$padding-large-horizontal:     48px !default;

$padding-small-vertical:        5px !default;
$padding-small-horizontal:     15px !default;

// $padding-xs-vertical:           1px !default;
// $padding-xs-horizontal:         5px !default;

$padding-label-vertical:        2px !default;
$padding-label-horizontal:     12px !default;

$margin-large-vertical:        30px !default;
$margin-base-vertical:         15px !default;

$margin-base-horizontal:       15px !default;

$margin-bottom:                 10px        !default;
$border:                        1px solid   !default;
$border-radius-extra-small:     0.125rem    !default;
$border-radius-small:           0.1875rem   !default;
$border-radius-large:           0.25rem     !default;
$border-radius-extreme:         0.875rem    !default;

$border-radius-large-top:      $border-radius-large $border-radius-large 0 0 !default;
$border-radius-large-bottom:   0 0 $border-radius-large $border-radius-large !default;

$btn-round-radius:             30px         !default;

$height-base:                  40px         !default;

$btn-icon-size:                 3.5rem       !default;
$btn-icon-size-regular:         2.375rem      !default;
$btn-icon-font-size-regular:    0.9375rem     !default;
$btn-icon-font-size-small:      0.6875rem      !default;
$btn-icon-size-small:           1.875rem     !default;
$btn-icon-font-size-lg:         1.325rem     !default;
$btn-icon-size-lg:              3.6rem         !default;

$font-size-h1:                 3.5em        !default; // ~ 49px
$font-size-h2:                 2.5em        !default; // ~ 35px
$font-size-h3:                 2em          !default; // ~ 28px
$font-size-h4:                 1.714em      !default; // ~ 24px
$font-size-h5:                 1.57em       !default; // ~ 22px
$font-size-h6:                 1em          !default; // ~ 14px

$font-paragraph:               1em          !default;
$font-size-navbar:             1em          !default;
$font-size-mini:               0.7142em     !default;
$font-size-small:              0.8571em     !default;
$font-size-base:               14px         !default;
$font-size-large:              1rem         !default;
$font-size-large-navbar:       20px         !default;
$font-size-blockquote:         1.1em        !default; // ~ 15px
$font-size-medium:             16px         !default;
$font-size-xs:                 12px         !default;

$font-weight-light:             300         !default;
$font-weight-normal:            400         !default;
$font-weight-semi:              600         !default;
$font-weight-bold:              700         !default;

$line-height-general:        1.5            !default;
$line-height-nav-link:       1.625rem       !default;
$btn-icon-line-height:       2.4em          !default;
$line-height:                1.35em         !default;
$line-height-lg:             54px           !default;


$border-radius-top:        10px 10px 0 0     !default;
$border-radius-bottom:     0 0 10px 10px     !default;

$dropdown-shadow:          1px 2px 7px 1px rgba(0,0,0,.125);
$box-shadow-raised:        0px 10px 25px 0px rgba(0, 0, 0, 0.3);
$box-shadow:               0 2px 2px rgba(204, 197, 185, 0.5);
$sidebar-box-shadow:       0px 2px 22px 0 rgba(0, 0, 0,.20), 0px 2px 30px 0 rgba(0, 0, 0,.35);

$general-transition-time:  300ms             !default;

$slow-transition-time:           370ms       !default;
$dropdown-coordinates:      29px -50px       !default;

$fast-transition-time:           150ms       !default;
$select-coordinates:         50% -40px       !default;

$transition-linear:         linear           !default;
$transition-bezier:         cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease:           ease 0s;


//$navbar-padding-a:               9px 13px;
$navbar-margin-a:                15px 0px;

$padding-social-a:               10px 5px;

$navbar-margin-a-btn:            15px 0px;
$navbar-margin-a-btn-round:      16px 0px;

$navbar-padding-a-icons:         6px 15px;
$navbar-margin-a-icons:          6px  3px;

$navbar-padding-base:            0.625rem;
//$navbar-margin-brand:             5px  0px;

$navbar-margin-brand-icons:      12px auto;
$navbar-margin-btn:              15px  3px;

$height-icon-sm:				 32px;
$width-icon-sm:					 32px;
$padding-icon-sm:			     4px;
$border-radius-icon-sm:			 7px;

$height-icon-message:			 40px;
$width-icon-message:			 40px;

$height-icon-message-sm: 		 20px;
$width-icon-message-sm:			 20px;

$white-navbar:                  rgba(#FFFFFF, .96);
$primary-navbar:                rgba(#34ACDC, .98);
$info-navbar:                   rgba(#5BCAFF, .98);
$success-navbar:                rgba(#4CD964, .98);
$warning-navbar:                rgba(#FF9500, .98);
$danger-navbar:                 rgba(#FF4C40, .98);

$topbar-x:             topbar-x !default;
$topbar-back:          topbar-back !default;
$bottombar-x:          bottombar-x !default;
$bottombar-back:       bottombar-back !default;

//Nucleo Icons

$nc-font-path:        '../fonts' !default;
$nc-font-size-base:   14px !default;
$nc-css-prefix:       nc !default;
$nc-background-color:     #eee !default;
$nc-li-width:         (30em / 14) !default;
$nc-padding-width:    (1em/3) !default;

// Social icons color
$social-twitter:                   #55acee !default;
$social-twitter-state-color:       darken(#55acee, 5%) !default;

$social-facebook: 			       #3b5998 !default;
$social-facebook-state-color:      darken(#3b5998, 5%) !default;

$social-google: 			       #dd4b39 !default;
$social-google-state-color:        darken(#dd4b39, 5%) !default;

$social-linkedin: 			         #0077B5 !default;
$social-linkedin-state-color:        darken(#0077B5, 5%) !default;

// Sidebar variables
$sidebar-width:              calc(100% - 260px) !default;
$sidebar-mini-width:         calc(100% - 80px) !default;


// Social icons color
$social-twitter:                   #55acee !default;
$social-twitter-state-color:       lighten(#55acee, 6%) !default;

$social-facebook: 			       #3b5998 !default;
$social-facebook-state-color:      lighten(#3b5998, 6%) !default;

$social-google: 			       #dd4b39 !default;
$social-google-state-color:        lighten(#dd4b39, 6%) !default;

$social-linkedin: 			       #0077B5 !default;
$social-linkedin-state-color:      lighten(#0077B5, 6%) !default;

$social-pinterest: 			       #cc2127 !default;
$social-pinterest-state-color:     lighten(#cc2127, 6%) !default;

$social-dribbble: 		           #ea4c89 !default;
$social-dribbble-state-color:      lighten(#ea4c89, 6%) !default;

$social-github: 		      	   #333333 !default;
$social-github-state-color:        lighten(#333333, 6%) !default;

$social-youtube: 		    	   #e52d27 !default;
$social-youtube-state-color:       lighten(#e52d27, 6%) !default;

$social-instagram: 		           #125688 !default;
$social-instagram-state-color:     lighten(#125688, 6%) !default;

$social-reddit: 		  	       #ff4500 !default;
$social-reddit-state-color:        lighten(#ff4500, 6%) !default;

$social-tumblr: 			       #35465c !default;
$social-tumblr-state-color:        lighten(#35465c, 6%) !default;

$social-behance: 			       #1769ff !default;
$social-behance-state-color:       lighten(#1769ff, 6%) !default;

// Placeholder text color
$input-color-placeholder: #999 !default;

$zindex-select-dropdown: 1060 !default; // must be higher than a modal background (1050)

////////// COLOR SYSTEM //////////

$blue:              #5E50F9;
$indigo:            #6610f2;
$purple:            #6a008a;
$pink:              #E91E63;
$red:               #f96868;
$orange:            #f2a654;
$yellow:            #f6e84e;
$green:             #46c35f;
$teal:              #58d8a3;
$cyan:              #57c7d4;
$black:             #000;
$white:             #ffffff;
$white-smoke:       #f2f7f8;
$violet:            #41478a;
$darkslategray :    #2e383e;
$dodger-blue :      #3498db;


$colors: (
  blue:             $blue,
  indigo:           $indigo,
  purple:           $purple,
  pink:             $pink,
  red:              $red,
  orange:           $orange,
  yellow:           $yellow,
  green:            $green,
  teal:             $teal,
  cyan:             $cyan,
  white:            $white,
  gray:             #434a54,
  gray-light:       #aab2bd,
  gray-lighter:     #e8eff4,
  gray-lightest:    #e6e9ed,
  gray-dark:        #0f1531,
  black:            #000000
);


$theme-colors: (
  primary:         #b66dff,
  secondary:       #d8d8d8,
  success:         #1bcfb4,
  info:            #198ae3,
  warning:         #fed713,
  danger:          #fe7c96,
  light:           #f8f9fa,
  dark:            #3e4b5b
);

$theme-gradient-colors: (
  primary:         linear-gradient(to right, #da8cff, #9a55ff),
  secondary:       linear-gradient(to right, #e7ebf0, #868e96),
  success:         linear-gradient(to right, #84d9d2, #07cdae),
  info:            linear-gradient(to right, #90caf9, #047edf 99%),
  warning:         linear-gradient(to right, #f6e384, #ffd500),
  danger:          linear-gradient(to right, #ffbf96, #fe7096),
  light:           linear-gradient(to bottom, #f4f4f4, #e4e4e9),
  dark:            linear-gradient(89deg, #5e7188, #3e4b5b)
);


////////// COLOR SYSTEM //////////

////////// COLOR VARIABLES //////////
$content-bg: #f2edf3;
$footer-bg: $content-bg;
$footer-color: theme-color(dark);
$border-color: #ebedf2;
$circle-border:$white; 

////////// COLOR VARIABLES //////////



////////// SOCIAL COLORS //////////

$social-colors: (
  twitter: #2caae1,
  facebook: #3b579d,
  google: #dc4a38,
  linkedin: #0177b5,
  pinterest: #cc2127,
  youtube: #e52d27,
  github: #333333,
  behance: #1769ff,
  dribbble: #ea4c89,
  reddit: #ff4500
);

////////// SOCIAL COLORS //////////

////////// FONTS//

$type1:     'Ubuntu', sans-serif;

$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;

$default-font-size: .875rem; // 14px as base font size

$text-muted: #9c9fa6;
$body-color: #343a40;

////////// FONT VARIABLES //////////

///////// CARD ////////
$card-description-color: #76838f;
$card-title-color: $body-color;
$card-bg-varient: #fff;
///////// CARD ////////

////////// SIDEBAR ////////
$sidebar-width-lg: 260px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

$sidebar-light-bg: $white;
$sidebar-light-menu-color: #3e4b5b;
$sidebar-light-submenu-color: #888;
$sidebar-light-menu-active-bg: $white;
$sidebar-light-menu-active-color: theme-color(primary);
$sidebar-light-menu-hover-bg: darken($sidebar-light-bg, 1%);
$sidebar-light-menu-hover-color: darken($sidebar-light-menu-color, 10%);
$sidebar-light-submenu-hover-color: darken($sidebar-light-submenu-color, 20%);
$sidebar-light-menu-icon-color: #bba8bff5;
$sidebar-light-menu-arrow-color: #9e9da0;
$sidebar-light-menu-border-color: #f2edf3;

$sidebar-dark-bg: #18151e;
$sidebar-dark-menu-color: $white;
$sidebar-dark-menu-active-bg: #282037;
$sidebar-dark-menu-active-color: darken($white, 30%);
$sidebar-dark-menu-hover-bg: lighten($sidebar-dark-bg, 10%);
$sidebar-dark-menu-hover-color: theme-color(white);
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: darken($sidebar-dark-submenu-color, 30%);
;
$sidebar-dark-menu-icon-color: #bba8bff5;
$sidebar-dark-menu-arrow-color: #9e9da0;

$sidebar-menu-font-size: $default-font-size;
$sidebar-menu-padding-y: 1.125rem;
$sidebar-menu-padding-x: 2.25rem;

$sidebar-submenu-font-size: .8125rem;
$sidebar-submenu-item-padding: .75rem 2rem .75rem 2rem;

$sidebar-icon-font-size: 1.125rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: .75rem 1.625rem .75rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: $sidebar-light-bg;
$icon-only-menu-bg-dark: $sidebar-dark-bg;

///////// SIDEBAR ////////

///////// NAVBAR ////////

$navbar-height: 70px;
$navbar-bg: #fff;
$navbar-menu-color: #9c9fa6;
$navbar-font-size: .875rem;
$navbar-icon-font-size: 1.25rem;

///////// NAVBAR ////////



///////// BUTTONS ////////

$button-fixed-width:        150px;
$btn-padding-y:       .875rem;
$btn-padding-x:       2.5rem;
$btn-line-height:     1;

$btn-padding-y-xs:    .5rem;
$btn-padding-x-xs:    .75rem;

$btn-padding-y-sm:    .50rem;
$btn-padding-x-sm:    .81rem;

$btn-padding-y-lg:    1rem;
$btn-padding-x-lg:    3rem;

$btn-font-size:       .875rem;
$btn-font-size-xs:    .625rem;
$btn-font-size-sm:    .875rem;
$btn-font-size-lg:    .875rem;

$btn-border-radius:         .1875rem;
$btn-border-radius-xs:      .1875rem;
$btn-border-radius-sm:      .1875rem;
$btn-border-radius-lg:      .1875rem;

///////// BUTTONS ////////



////////// TOOLTIP VARIABLES //////////

//default styles
$tooltip-font-size:     .75rem;
$tooltip-padding-y:     .4rem;
$tooltip-padding-x:     .75rem;
$tooltip-border-radius: .375rem;

////////// TOOLTIP VARIABLES //////////



/////////  FORMS /////////

$input-bg:                theme-color(white);
$input-border-radius:     2px;
$input-placeholder-color: #c9c8c8;
$input-font-size: .8125rem;

$input-padding-y:       .875rem;
$input-padding-x:       1.375rem;
$input-line-height:     1;

$input-padding-y-xs:    .5rem;
$input-padding-x-xs:    .75rem;

$input-padding-y-sm:    .50rem;
$input-padding-x-sm:    .81rem;

$input-padding-y-lg:    .94rem;
$input-padding-x-lg:    1.94rem;

$input-height:          2.875rem;
$input-height-sm:       2.575rem;
$input-height-lg:       3.175rem;

///////// FORMS /////////

////////  DROPDOWNS ///////

$dropdown-border-color: $border-color;
$dropdown-divider-bg:   $border-color;
$dropdown-link-color:   $body-color;
$dropdown-header-color: $body-color;
////////  DROPDOWNS ///////

//////// TABLES ////////

$table-accent-bg: $content-bg;
$table-hover-bg:  $content-bg;
$table-cell-padding: .9375rem;
$table-border-color: $border-color;

$table-inverse-bg: #2a2b32;
$table-inverse-color: theme-color(white);

//////// TABLES ////////



////////// MEASUREMENT AND PROPERTY VARIABLES //////////

$border-property: 1px solid $border-color;
$card-spacing-y: 2.5rem;
$card-padding-y: 2rem;
$card-padding-x: 2.5rem;
$card-border-radius: .3125rem;
$grid-gutter-width: 40px;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
////////// OTHER VARIABLES //////////



////////// BREAD CRUMBS VARIABLES //////////

// default styles
$breadcrumb-padding-y:              0.56rem;
$breadcrumb-padding-x:              1.13rem;
$breadcrumb-item-padding:           .5rem;
$breadcrumb-margin-bottom:          1rem;
$breadcrumb-font-size:              $default-font-size;
$breadcrumb-bg:                     transparent;
$breadcrumb-border-color:           $border-color;
$breadcrumb-divider:                "/";

// custom styles
$breadcrumb-custom-padding-y:       0;
$breadcrumb-custom-padding-x:       0;
$breadcrumb-custom-item-padding-y:  0.56rem;
$breadcrumb-custom-item-padding-x:  10px;
$breadcrumb-custom-item-color:      $black;
$breadcrumb-item-bg:                #dbe3e6;

////////// BREAD CRUMBS VARIABLES //////////



////////// MODALS VARIABLES //////////

$modal-inner-padding:               0.9375rem;
$modal-dialog-margin:               10px;
$modal-dialog-margin-y-sm-up:       30px;
$modal-content-bg:                  $content-bg;
$modal-content-box-shadow-xs:       0 3px 9px rgba($black,.5);
$modal-content-box-shadow-sm-up:    0 5px 15px rgba($black,.5);

$modal-backdrop-bg:                 $black;
$modal-backdrop-opacity:            .5;

$modal-header-border-color:         $border-color;
$modal-content-border-color:        $border-color;
$modal-footer-border-color:         $border-color;



$modal-header-padding-x:              26px;
$modal-header-padding-y:              25px;

$modal-body-padding-x:                26px;
$modal-body-padding-y:                35px;

$modal-footer-padding-x:              31px;
$modal-footer-padding-y:              15px;

$modal-lg:                          90%;
$modal-md:                          500px;
$modal-sm:                          300px;
$modal-transition:                  transform .4s ease;

////////// MODALS VARIABLES //////////

/////////  TABS VARIABLES //////////
$nav-tabs-border-color:             #ebedf2;
$nav-tabs-link-bg:                  #f6f8fa;
$nav-tabs-link-color:               #000000;
$nav-tabs-link-hover-border-color:  $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color;
$nav-tabs-link-active-color:        $body-color;
$nav-tabs-link-active-bg:           $white;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-link-active-bg;
$nav-pills-custom-bg:               #fcfcfd;
$text-gray:gray;
$circle-img-border: $white;
////////   TABS VARIABLES /////////

////////   CALENDAR  //////////////
$fullcalendar-color: #a8b2b9;
////////   CALENDAR  //////////////

///////// SETTINGS PANEL ////////
$settings-panel-width: 300px;
///////// SETTINGS PANEL ////////

////////// Vendor file asset path //////////
$mdi-font-path:        "~@mdi/font/fonts";
$fa-font-path : '~font-awesome/fonts/';
$flag-icon-css-path : '~flag-icon-css/flags/';
$simple-line-font-path : '~simple-line-icons/fonts/';


///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////

///////// TICKETS-TAB ///////
$tab-head-bg: darken(#f2edf3, 2%);
$accent-color: $white; 
///////// TICKETS-TAB ///////

///////// LANDING PAGE ///////
$landing-bg:#1a142dfa;
$landing-bg-color: $white;
///////// LANDING PAGE ///////

///////// CHATS ///////
$chat-container-height:100%;
$chat-bottom-btn: $white;
$form-focus-bg: $white;
///////// CHATS ///////

///////// KANBAN ///////
$private-btn-bg:#eae2ec;
///////// KANBAN ///////

$bullet-line-list-shape-bg: theme-color(white) !default;