// .btn,
// .navbar .navbar-nav > a.btn{
//     border-width: $border-thick;
//     font-weight: $font-weight-semi;
//     font-size: $font-size-small;
//     line-height: $line-height;
//     text-transform: uppercase;
//     border: none;
//     margin: 10px 1px;
//     border-radius: $border-radius-small;
//     padding: $padding-btn-vertical $padding-btn-horizontal;
//     cursor: pointer;

//     @include btn-styles($default-color, $default-states-color);
//     @include transition($fast-transition-time, linear);

//     &:hover,
//     &:focus{
//         @include opacity(1);
//         outline: 0 !important;
//     }
//     &:active,
//     &.active,
//     .open > &.dropdown-toggle {
//          @include box-shadow(none);
//          outline: 0 !important;
//     }

//     .badge{
//       margin: 0;
//     }

//     &.btn-icon {
//         // see above for color variations
//         height: $btn-icon-size-regular;
//         min-width: $btn-icon-size-regular;
//         width: $btn-icon-size-regular;
//         padding: 0;
//         font-size: $btn-icon-font-size-regular;
//         overflow: hidden;
//         position: relative;
//         line-height: normal;

//         &.btn-simple{
//             padding: 0;
//         }

//         &.btn-sm{
//             height: $btn-icon-size-small;
//             min-width: $btn-icon-size-small;
//             width: $btn-icon-size-small;

//             .fa,
//             .far,
//             .fas,
//             .nc-icon{
//                 font-size: $btn-icon-font-size-small;
//             }
//         }

//         &.btn-lg{
//             height: $btn-icon-size-lg;
//             min-width: $btn-icon-size-lg;
//             width: $btn-icon-size-lg;

//             .fa,
//             .far,
//             .fas,
//             .nc-icon{
//                 font-size: $btn-icon-font-size-lg;
//             }
//         }

//         &:not(.btn-footer) .nc-icon,
//         &:not(.btn-footer) .fa,
//         &:not(.btn-footer) .far,
//         &:not(.btn-footer) .fas{
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             transform: translate(-12px, -12px);
//             line-height: 1.5626rem;
//             width: 24px;
//         }

//         &.btn-neutral {
//           font-size: 20px;
//         }
//     }

//     &:not(.btn-icon) .nc-icon{
//         position: relative;
//         top: 1px;
//     }
// }

// // Apply the mixin to the buttons
// // .btn-default { @include btn-styles($default-color, $default-states-color); }
// .btn-primary { @include btn-styles($primary-color, $primary-states-color); }
// .btn-success { @include btn-styles($success-color, $success-states-color); }
// .btn-info    { @include btn-styles($info-color, $info-states-color); }
// .btn-warning { @include btn-styles($warning-color, $warning-states-color); }
// .btn-danger  { @include btn-styles($danger-color, $danger-states-color); }
// // .btn-neutral { @include btn-styles($white-color, $white-color); }

// .btn-outline-default { @include btn-outline-styles($default-color, $default-states-color); }
// .btn-outline-primary { @include btn-outline-styles($primary-color, $primary-states-color); }
// .btn-outline-success { @include btn-outline-styles($success-color, $success-states-color); }
// .btn-outline-info    { @include btn-outline-styles($info-color, $info-states-color); }
// .btn-outline-warning { @include btn-outline-styles($warning-color, $warning-states-color); }
// .btn-outline-danger  { @include btn-outline-styles($danger-color, $danger-states-color); }
// .btn-outline-neutral { @include btn-outline-styles($white-color, $default-states-color);
//     &:hover,
//     &:focus{
//         color: $default-states-color;
//         background-color: $white-color;
//     }
// }
// .btn-neutral {
//     @include btn-styles($white-color, $white-color);
//     color: $default-color;
//     &:hover,
//     &:focus{
//         color: $default-states-color;
//     }

//     &.btn-border{
//         &:hover,
//         &:focus{
//             color: $default-color;
//         }

//         &:active,
//         &.active,
//         .open > &.dropdown-toggle{
//              background-color: $white-color;
//              color: $default-color;
//         }
//     }

//     &.btn-link:active,
//     &.btn-link.active{
//         background-color: transparent;
//     }
// }

// .btn{
//      &:disabled,
//      &[disabled],
//      &.disabled{
//         @include opacity(.5);
//         pointer-events: none;
//     }
// }
// .btn-simple{
//     border: $border;
//     border-color: $default-color;
//     padding: $padding-btn-vertical - 1 $padding-round-horizontal - 1;
//     background-color: $transparent-bg;
// }

// .btn-simple,
// .btn-link{
//     &.disabled,
//     &:disabled,
//     &[disabled],
//     fieldset[disabled] & {
//         &,
//         &:hover,
//         &:focus,
//         &.focus,
//         &:active,
//         &.active {
//             background-color: $transparent-bg;
//         }
//     }
// }

// .btn-link{
//   border: $none;
//   padding: $padding-base-vertical $padding-base-horizontal;
//   background-color: $transparent-bg;
// }

// .btn-lg{
//    @include btn-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
// }
// .btn-sm{
//     @include btn-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
// }

// .btn-wd {
//     min-width: 140px;
// }
// .btn-group.select{
//     width: 100%;
// }
// .btn-group.select .btn{
//     text-align: left;
// }
// .btn-group.select .caret{
//     position: absolute;
//     top: 50%;
//     margin-top: -1px;
//     right: 8px;
// }
// .btn-group {
//   .btn + .btn {
//     margin-left: -3px;
//   }
//   .btn {
//     &:focus {
//       background-color: $info-color !important;
//     }
//   }
// }


// .btn-round{
//     border-width: $border-thin;
//     border-radius: $btn-round-radius;
//     padding-right: $padding-round-horizontal;
//     padding-left: $padding-round-horizontal;

//     &.btn-simple{
//         padding: $padding-btn-vertical - 1  $padding-round-horizontal - 1;
//     }
// }

// .no-caret {
//   &.dropdown-toggle::after {
//     display: none;
//   }
// }

/* Buttons */

.btn {
    font-size: $btn-font-size;
    line-height: 1;
    font-weight: $font-weight-bold;
    margin-right: 3px;
    i {
      font-size: 1rem;
    }
    &.btn-rounded {
    border-radius: 50px;
    }
    .btn-gradient-success {
      background: linear-gradient(to right, #84d9d2, #07cdae) !important;
    border: 0 !important;
    transition: .3s ease !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    }
    &.btn-fw {
      min-width: $button-fixed-width;
    }
    &.btn-sm {
      font-size: $btn-font-size-sm;
    }
    &.btn-lg {
      font-size: $btn-font-size-lg;
    }
    &.btn-xs {
      padding: $btn-padding-y-xs $btn-padding-x-xs;
      font-size: $btn-font-size-xs;
    }
    /* Buttons with only icons */
    &.btn-icon {
      width: 42px;
      height: 42px;
      padding: 0;
    }
    /* Buttons with icon and text */
    &.btn-icon-text {
      .btn-icon-prepend {
        margin-right: .5rem;
      }
      .btn-icon-append {
        margin-left: .5rem;
      }
    }
    &.btn-social-icon {
      width: 50px;
      height: 50px;
      padding: 0;
    }
  }
  @media (min-width: 576px) {
    .btn-group {
      .btn {
        margin-right: 0;
        + .btn {
          border-left: 0;
        }
      }
    }
  }
  
  @media (max-width: 575px) {
    .btn-group {
      flex-direction: column;
  
      > .btn {
        border-radius: 0;
        margin-right: 0;
        margin-left: 0;
  
        &:first-child:not(.dropdown-toggle) {
          border-top-left-radius: 0.1875rem;
          border-top-right-radius: 0.1875rem;
        }
  
        &:last-child:not(.dropdown-toggle) {
          border-bottom-left-radius: 0.1875rem;
          border-bottom-right-radius: 0.1875rem;
        }
  
        &:not(first-child):not(.last-child) {
          border-radius: 0;
        }
  
        + .btn {
          border-top: 0;
          margin-left: 0;
        }
      }
    }
  }
  
//   .btn-toolbar {
//     .btn-group {
//       +.btn-group {
//         @extend .ml-2;
//       }
//     }
//   }
  /*social buttons*/
  @each $color, $value in $social-colors {
    .btn-#{$color} {
      @include social-button(social-color($color));
    }
    .btn-outline-#{$color} {
      @include social-outline-button(social-color($color));
    }
  }
  /* inverse buttons */
  @each $color, $value in $theme-colors {
    .btn-inverse-#{$color} {
      @include button-inverse-variant($value);
    }
    .btn-#{$color}:not(.btn-light) {
      color: $white;
      &:hover,
      &:focus,
      &:active {
        color: $white;
      }
      &:focus,
      &:active {
        background: $value;
        border-color: $value;
      }
    }
    .btn-outline-#{$color} {
      &:hover,
      &:focus,
      &:active {
        background: theme-gradient-color($color);
        color: $white;
      }
    }
  }
  /* gradient buttons */
  @each $color, $value in $theme-gradient-colors {
    .btn-gradient-#{$color} {
      @include button-gradient-variant($value);
    }
  }


  .bg-gradient-info {
    background: linear-gradient(to right, #90caf9, #047edf 99%);
  }
  .bg-gradient-success {
    background: linear-gradient(to right, #84d9d2, #07cdae);
  }
  .bg-gradient-danger {
    background: linear-gradient(to right, #ffbf96, #fe7096);
  }

  .btn:not([data-action]):hover, .navbar .navbar-nav > a.btn:not([data-action]):hover {
    color: #fff;
}

.password_icon {
  
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  background: transparent;
  border:none;
  z-index:3
}
.password_icon:focus{
  outline: none !important;
}
