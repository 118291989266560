.payment{
.font{
font-family: 'Inter';
font-style: normal;
}
.block1{
    width: 534px;
    height: 92.73px;
    justify-content: center;
}
.block1>h1{
  
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 23px;
text-align: center;
color: #3990B5;
}
.block1>p,p{
    width: 423.15px;
    height: 47px;
    font-weight: 400;
    font-size: 13.95px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
}
.block2{
    width: 534.71px;
    height: 122px;
    margin-top: 186px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.block2>.Row{
width: 120px;
height: 120px;
left: calc(50% - 120px/2 - 0.36px);
top: calc(50% - 120px/2 + 0.28px);
background: #26A212;
border-radius: 60px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

}
.block3{
    width: 527px;
    height: 92.73px;
    margin-top: 45px !important;

justify-content: center;
}
.block3>h1{
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
text-align: center;
color: #020246;
}

.block4{
    width: 527px;
    height: 92.73px;
    margin-bottom: 55px;
}
.block4>span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13.78px;
    color: #101828;
    
}
p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 0.3px; 
    color: #4D4D4D;
}
/* 


.block2f {
    width: 534.71px;
    height: 122px;
    margin-top: 186px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.block2f>.Row {
    width: 120px;
    height: 120px;
    left: calc(50% - 120px/2 - 0.36px);
    top: calc(50% - 120px/2 + 0.28px);
    background: #FF3B30;
    border-radius: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.block3f {
    width: 527px;
    height: 92.73px;
   
}


.block4f{
    width: 527px;
    height: 92.73px;
    margin-bottom: 55px;
}
.border420 {
    width: 420px;
    border-bottom:1px solid #c5c3c3;
}

.block4f>span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13.78px;
    color: #101828;

}

 */
 .img>img {
    width: 78px;
    height: 40px;
}
.pay{
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
display: flex;
align-items: center;
color: #007AFF;

}
.failed{
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 26px;
text-align: center;
color: #1A1A1A;
}
}