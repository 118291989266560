.table-head-content {
    padding-left: 5px;
    font-weight: bold;
}
.card label{
    font-size: 0.95em;
    color: #414141;
    font-weight: bold;
    
}
.description-for-button{
    font-size: 0.95em;
    color: #414141;
    font-weight: bold;
    padding: 15px;
}

.border-div {
    border-style: solid; 
    border-width: 1px; 
    padding: 10px;

}

.card-product{
  border: #dededd;
  border-style: solid;
  border-width: 1px;
}

.year-make{
    font-size: 100%;
    font-weight: 500;
    color: #bf1e2e;
  }
  
  .model-trim{
    font-size: 150%;
    font-weight: 600;
    color: #bf1e2e;
  }
  
  
  .model-trim-desc{
    display: inline-block;
    font-size: 100%;
    font-weight: 600;
    color: #000000;
    /* height: 55px; */
  }
  
  .product-description {
    display: inline-block;
    color: #000000;
    font-size: 100%;
    font-weight: 600;
    /* height: 55px; */
  }

  .extra-text-finance {
    padding: 10px;
    font-weight: 600;
    font-size:110%;
  }
  
  .extra-text-about p {
    padding: 10px;
    font-weight: 500;
    font-size:150%;
  }