.wrapper{
    position: relative;
    top: 0;
    height: 100vh;

    &.wrapper-full-page{
        min-height: 100vh;
        height: auto;
    }
}

.sidebar,
.off-canvas-sidebar{
    position: fixed;
    top: 0;
    height: 100%;
    bottom: 0;
    width: 260px;
    left: 0;
    z-index: 999;

    .sidebar-wrapper{
        position: relative;
        height: calc(100vh - 0px);
        overflow: auto;
        width: 260px;
        z-index: 4;
        padding-bottom: 100px;

        .dropdown .dropdown-backdrop{
          display: none !important;
        }

        .navbar-form{
            border: none;
        }

        > .nav,
        .user .info{
          [data-toggle="collapse"] ~ div > ul > li > a{
              span{
                display: inline-block;
                @extend .animation-transition-general;
              }

              .sidebar-normal {
                margin: 0;
                position: relative;
                transform: translateX(0px);
                opacity: 1;
                white-space: nowrap;
                display: block;
                line-height: 20px;
                z-index: 1;
              }

              .sidebar-mini-icon {
                text-transform: uppercase;
                width: 34px;
                margin-right: 10px;
                margin-left: 0px;
                font-size: 12px;
                text-align: center;
                line-height: 20px;
                position: relative;
                float: left;
                z-index: 1;
                display: inherit;
                color: $opacity-5;
              }

              .sidebar-normal {
                text-transform: capitalize;
              }

              i{
                font-size: 17px;
                line-height: 20px;
                width: 26px;
              }
            }
        }
    }

    .navbar-minimize{
      position: absolute;
      right: 20px;
      top: 2px;
      opacity: 1;

      @extend .animation-transition-general;
    }
    .logo-tim{
      border-radius: 50%;
      border: 1px solid #333;
      display: block;
      height: 61px;
      width: 61px;
      float: left;
      overflow: hidden;

      img{
          width: 60px;
          height: 60px;
      }
    }

    .nav{
        margin-top: 20px;
        display: block;

        .caret{
            top: 14px;
            position: absolute;
            right: 10px;
        }

        li{
            > a + div .nav li > a{
                margin-top: 7px;
            }

            > a{
                margin: 10px 15px 0;
                color: $white-color;
                display: block;
                text-decoration: none;
                position: relative;
                text-transform: uppercase;
                cursor: pointer;
                font-size: 12px;
                padding: 10px 8px;
                line-height: 30px;
                opacity: .7;
            }

            .nav > li > a{
              padding: 5px 8px;
            }

            &.active > a,
            &.active > a > i {
              opacity: 1;
              // color: $danger-color;
            }

            &:hover:not(.active) > a,
            &:focus:not(.active) > a {
                opacity: 1;
            }

            &.active > a[data-toggle="collapse"] > {
                background-color: transparent;
                box-shadow: $box-shadow;
            }

            &.active > a[data-toggle="collapse"],
            &.active > a[data-toggle="collapse"] i,
            &.active > a[data-toggle="collapse"] ~ div > ul > li.active i,
            &.active > a[data-toggle="collapse"] ~ div > ul > li.active > a {
              color: $danger-color;

              & + div .nav .active a {
                background-color: transparent;
                box-shadow: none;

                &:after {
                  content: "";
                  position: absolute;
                  width: calc(100% - 5px);
                  z-index: 0;
                }
              }
            }

            &.active > a[data-toggle="collapse"] ~ div > .nav {
              margin-top: 0;
            }
        }

        p{
            margin: 0;
            line-height: 30px;
            position: relative;
            display: block;
            height: auto;
            white-space: nowrap;
            font-weight: 600;
            @extend .animation-transition-general;
        }

        i{
            font-size: 24px;
            float: left;
            margin-right: 12px;
            line-height: 30px;
            width: 34px;
            text-align: center;
            color: $opacity-5;
            position: relative;
        }

        .collapse,
        .collapsing {
          .nav {
            margin-top: 0;
          }
        }
    }

    .sidebar-background{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;

        &:after{
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            background: #FFFFFF;
            opacity: 1;
        }
    }

    .logo{
        position: relative;
        padding: 7px $padding-base-horizontal;
        z-index: 4;

        a.logo-mini,
        a.logo-normal{
            @extend .animation-transition-general;
        }

        a.logo-mini{
            opacity: 0;
            float: left;
            width: 34px;
            text-align: center;
            margin-left: 10px;
            margin-right: 12px;
        }

        a.logo-normal{
            display: block;
            opacity: 1;
            padding: 11px 0 8px;
            @include transform-translate-x(0px);
        }

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: $opacity-5;

        }

        p{
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
            font-family: 'Muli', "Helvetica", Arial, sans-serif;
        }

        .simple-text{
            text-transform: uppercase;
            padding: $padding-base-vertical 0;
            display: block;
            white-space: nowrap;
            font-size: $font-size-large;
            color: $white-color;
            text-decoration: none;
            font-weight: $font-weight-normal;
            line-height: 30px;
            overflow: hidden;
        }
    }

    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img{
            width: 60px;
            height: 60px;
        }
    }

    &:before,
    &:after{
        display: block;
        content: "";
        opacity: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &:after{
        @include icon-gradient($default-color);
        z-index: 3;
    }

    &[data-color="white"]{
      @include sidebar-color($white-color);
      @include sidebar-text-color($default-color);
    }
    &[data-color="brown"]{
      @include sidebar-color($default-color);
    }

    // Active color changing

    &[data-active-color="primary"]{
        @include sidebar-active-color($primary-color);
    }
    &[data-active-color="info"]{
        @include sidebar-active-color($info-color);
    }
    &[data-active-color="success"]{
        @include sidebar-active-color($success-color);
    }
    &[data-active-color="warning"]{
        @include sidebar-active-color($warning-color);
    }
    &[data-active-color="danger"]{
        @include sidebar-active-color($danger-color);
    }

    .user{
        padding-bottom: 20px;
        margin:20px auto 0;
        position: relative;

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: rgba(255,255,255, .3);
        }

        .photo{
            width: 34px;
            height: 34px;
            overflow: hidden;
            float: left;
            z-index: 5;
            margin-right: 10px;
            border-radius: 50%;
            margin-left: 23px;
            @extend .animation-transition-general;

            img{
                width: 100%;
            }
        }

        a{
            color: $white-color;
            text-decoration: none;
            padding: $padding-base-vertical 15px;
            white-space: nowrap;
            @extend .animation-transition-general;
        }

        .info{
            > a{
               display: block;
               line-height: 18px;

                > span{
                   @extend .animation-transition-general;
                   display: block;
                   position: relative;
                   opacity: 1;
                }
            }

            .caret{
                position: absolute;
                top: 8px;
                right: 18px;
            }
        }
    }
}

.visible-on-sidebar-regular{
    display: inline-block !important;
}
.visible-on-sidebar-mini{
    display: none !important;
}

.off-canvas-sidebar{
    .nav {
        > li > a,
        > li > a:hover{
            color: $white-color;
        }

        > li > a:focus{
            background: rgba(200, 200, 200, 0.2);
        }
    }
}


.main-panel{
    position: relative;
    float: right;
    width: $sidebar-width;
    background-color: #f4f3ef;

    @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    > .content{
        padding: 0 30px 30px;
        min-height: calc(100vh - 123px);
        margin-top: 40px;
    }

    > .navbar{
        margin-bottom: 0;
    }


    .header{
        margin-bottom: 50px;
    }
}


.perfect-scrollbar-on{
  .sidebar,
  .main-panel{
      height: 100%;
      max-height: 100%;
  }
}


@media (min-width: 991px) {
    .sidebar,
    .main-panel,
    .sidebar-wrapper{
        -webkit-transition-property: top,bottom,width;
        transition-property: top,bottom, width;
        -webkit-transition-duration: .2s,.2s, .35s;
        transition-duration: .2s,.2s, .35s;
        -webkit-transition-timing-function: linear,linear,ease;
        transition-timing-function: linear,linear,ease;
        -webkit-overflow-scrolling: touch;
    }


    .sidebar-mini{
        .visible-on-sidebar-regular{
          display: none !important;
        }
        .visible-on-sidebar-mini{
          display: inline-block !important;
        }

        .sidebar,
        .sidebar .sidebar-wrapper{
          width: 80px;
        }

        .main-panel{
          width: $sidebar-mini-width;
        }

        .sidebar{
          display: block;
          z-index: 1030;

          .logo{
              a.logo-normal{
                  opacity: 0;
                  @include transform-translate-x(-25px);
              }
              a.logo-mini{
                  opacity: 1;
              }
          }

          .sidebar-wrapper{
            > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
            .user .info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
            .user .info > a > span,
            > .nav li > a p{
                @include transform-translate-x(-25px);
                opacity: 0;
            }
          }
        }

        .sidebar:hover {
          width: 260px;

          .logo {
            a.logo-normal {
                opacity: 1;
                @include transform-translate-x(0);
            }
          }

          .navbar-minimize{
            opacity: 1;
          }
          .sidebar-wrapper{
            width: 260px;

            > .nav li > a p,
            > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
            .user .info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
            .user .info > a > span{
              @include transform-translate-x(0px);
              opacity: 1;
            }
          }
        }
  }

}

.panel-header {
  height: 260px;
  padding-top: 80px;
  padding-bottom: 45px;
  background: #141E30;  /* fallback for old browsers */
  background: -webkit-gradient(linear, left top, right top, from(#0c2646), color-stop(60%, #204065), to(#2a5788));
  background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
  position: relative;
  overflow: hidden;

  .header{
    .title{
      color: $white-color;
    }
    .category{
      max-width: 600px;
      color: $opacity-5;
      margin: 0 auto;
      font-size: 13px;

      a{
        color: $white-color;
      }
    }
  }
}

.panel-header-sm{
  height: 135px;
}

.panel-header-lg{
  height: 380px;
}


// Stying for logos

.sidebar .logo .simple-text .logo-img{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    background: #FFFFFF;
    border-radius: 50%;
    text-align: center;
    overflow: hidden;

    img{
      width: 51%;
      vertical-align: unset;
      height: 55px;
    }
}

.sidebar {
  &[data-color="white"] {
    border-right: 1px solid #ddd;
  }
}

.sidebar .nav li > a,
    .off-canvas-sidebar .nav li > a {

      color: #000000 !important;
    }

    // .sidebar[data-active-color="info"] .nav li.active > a i {
    //   color: purple !important;
    // }

    // .sidebar .nav li.active > a i {
    //   color: #b66dff !important;
    //   font-weight: 500;
    // }
    // .sidebar .nav li.active > a  {
    //   color: #b66dff !important;
    // font-weight: 500;
    // }

    .sidebar-wrapper::-webkit-scrollbar {
      width: 10px;
    background-color: red;
    color: red;
    }
    
    /* Track */
    .sidebar-wrapper::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    
    /* Handle */
    .sidebar-wrapper::-webkit-scrollbar-thumb {
      background: #f4f3ef;
    }
    
    /* Handle on hover */
    .sidebar-wrapper::-webkit-scrollbar-thumb:hover {
      background: #dbdbdb;
    }

    .nav li:hover {
background: #fcfcfc;
    }

    .sidebar .user .info > a, .off-canvas-sidebar .user .info > a {      
      color: #000 !important;
  }

  .sidebar .user,
  .off-canvas-sidebar .user {
    padding-bottom: 0px !important;
    margin: 30px auto 0 !important;
    position: relative; }


    


    .sidebar {
      min-height: calc(100vh - #{$navbar-height});
      background: $sidebar-light-bg;
      padding: 0;
      width: $sidebar-width-lg;
      z-index: 11;
      transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    
      .nav {
        overflow: hidden;
        flex-wrap: nowrap;
        flex-direction: column;
        &:not(.sub-menu) {
          padding-bottom: 60px;
        }
    
        .nav-item {
          // padding:0 $sidebar-menu-padding-x;
          // @include transition-duration(0.25s);
        //  transition-duration: 0.25s;
          transition-property: background;
          -webkit-transition-property: background;
          .ReactCollapse--collapse {
            transition: height 500ms;
          }
          .collapse {
            z-index: 999;
          }
    
          .nav-link {
            // @include display-flex;
            // @include align-items(center);
            display: flex;
            align-items: center;
            white-space: nowrap;
            // padding:$sidebar-menu-padding-y 0 $sidebar-menu-padding-y 0;
            // color: $sidebar-light-menu-color;
            // transition-duration(0.45s);
            transition-property: color;
            -webkit-transition-property: color;
            cursor: pointer;
    
            i {
              color: #000 !important;
    
              &.menu-icon {
                font-size: $sidebar-icon-font-size;
                line-height: 1;
                margin-left: auto;
                .rtl & {
                  margin-left: 0;
                  margin-right: auto;
                }
                color: #000 !important;
                &:before {
                  vertical-align: middle;
                }
              }
    
              &.menu-arrow {
                font: normal normal normal 24px/1 "Material Design Icons";
                line-height: 1;
                font-size: $sidebar-icon-font-size;
                margin-left: auto;
                transition: transform .2s ease-in-out;
                .rtl & {
                  margin-left: 0;
                  margin-right: auto;
                }
                color: $sidebar-light-menu-arrow-color;
                &:before{
                  content: "\f141";
                  font-size: inherit;
                  color: inherit;
                  .rtl & {
                    content: "\f142";
                  }
                }
                + .menu-icon {
                  margin-left: .25rem;
                  margin-bottom: .25rem;
                  .rtl & {
                    margin-left: 0;
                    margin-right: .25rem;
                  }
                }
              }
            }
    
            .menu-title {
              color: inherit;
              display: inline-block;
              font-size: $sidebar-menu-font-size;
              line-height: 1;
              vertical-align: middle;
            }
    
            .badge {
              margin-right: auto;
              margin-left: 1rem;
            }
    
            &.menu-expanded {
              .menu-arrow{
                  transform: rotate(-90deg);
              }
            }
          }
    
          &.active {
            .nav-link {
              .menu-title {
                color: #000;
                font-weight: 550 !important;         
              }
              // i {
              //   color: theme-color(primary);
              // }
            }
          }
    
          &:hover{
            background: $sidebar-light-menu-hover-bg;
          }
          &.nav-profile {
            .nav-link {
              height: auto;
              line-height: 1;
              border-top: 0;
              padding: 1.25rem 0;
              .nav-profile-image {
                width: 44px;
                height: 44px;
                img {
                  width: 44px;
                  height: 44px;
                  border-radius: 100%;
                }
              }
              .nav-profile-text {
                display: flex;
                flex-direction: column;
                margin-left: 1rem;
                .rtl & {
                  margin-left: auto;
                  margin-right: 1rem;
                }
              }
              .nav-profile-badge {
                font-size: 1.125rem;
                margin-left: auto;
                .rtl & {
                  margin-left: 0;
                  margin-right: auto;
                }
              }
            }
          }
          &.sidebar-actions {
            margin-top: 1rem;
            .nav-link {
              border-top: 0;
              display: block;
              height: auto;
            }
            &:hover {
              background: initial;
              .nav-link {
                color: initial;
              }
            }
          }
        }
    
        &:not(.sub-menu) {
          > .nav-item {
            &:hover {
              &:not(.nav-category):not(.nav-profile) {
                > .nav-link {
                  color: $sidebar-light-menu-hover-color;
                }
              }
            }
          }
        }
        &.sub-menu {
          margin-bottom: 20px;
          margin-top:0;
          list-style: none;
    
          .nav-item {
            padding: 0;
            .nav-link {
              color: $sidebar-light-submenu-color;
              padding: $sidebar-submenu-item-padding;
              position: relative;
              font-size: $sidebar-submenu-font-size;
              line-height: 1;
              height: auto;
              border-top: 0;
              &:before {
                content: "\F054";
                font-family: "Material Design Icons";
                display: block;
                position: absolute;
                left:0px;
                .rtl & {
                  left: auto;
                  right: 0;
                  content: "\F04D";
                }
                top:50%;
                // @include transform(translateY(-50%));
                color: lighten($sidebar-light-submenu-color, 10%);
                font-size: .75rem;
              }
              &.active {
                color: $sidebar-light-menu-active-color;
                background: transparent;
              }
              &:hover {
                color: $sidebar-light-submenu-hover-color;
              }
            }
            &:hover {
              background: transparent;
            }
          }
        }
      }
    }
    
    
    /* style for off-canvas menu*/
    @media screen and (max-width: 991px) {
      .sidebar-offcanvas {
        position: fixed;
        max-height: calc(100vh - #{$navbar-height});
        top: $navbar-height;
        bottom: 0;
        overflow: auto;
        right: -$sidebar-width-lg;
        -webkit-transition: all 0.25s ease-out;
        -o-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out;
        &.active {
          right: 0;
        }
      }
    }


    .sidebar .nav li > a, .off-canvas-sidebar .nav li > a {
      padding: 7px 15px !important;
      margin: 0 !important;
    }
    .sidebar-mini .sidebar:hover .nav li > a{
      padding: 7px 12px !important;
    }
    

    .sidebar .nav .nav-item.active .nav-link .menu-title.active > span {
      color: #b66dff !important;
      font-weight: 550 !important;
    }
    .menu-title li.active > a span  {
      color: #b66dff !important;
    font-weight: 550;
    }

    .sidebar .nav:not(.sub-menu) {
      padding-bottom: 10px !important;
    }

    .navmenu {
      font-size: 30px !important;
    }


    // Sidebar Icon Only
// .sidebar-icon-only {
//   @media (min-width: 992px) {
//     .navbar {
//       .navbar-brand-wrapper {
//         width: $sidebar-width-icon;
//         .brand-logo {
//           display: none;
//         }
//         .brand-logo-mini {
//           display: inline-block;
//         }
//       }
//       .navbar-menu-wrapper {
//         width: calc(100% - #{$sidebar-width-icon});
//       }
//     }
//     .sidebar {
//       width: $sidebar-width-icon;
//       .nav {
//         overflow: visible;
//         .nav-item {
//           position: relative;
//           padding: 0;
//           .nav-link {
//             display: block;
//             text-align: center;
//             .menu-title,
//             .badge,.menu-sub-title {
//               display: none;
//             }
//             .menu-title {
//               border-radius : 0 5px 5px 0px;
//               @at-root #{selector-append(".rtl", &)} {
//                 border-radius:5px 0 0 5px;

//               }
//             }
//             i {
//               &.menu-icon {
//                 margin-right: 0;
//                 margin-left: 0;
//               }
//               &.menu-arrow {
//                 display: none;
//               }
//             }
//             &[aria-expanded] {
//               .menu-title{
//              border-radius:0 5px 0 0px;
//                 @at-root #{selector-append(".rtl", &)} {
//              border-radius:5px 0 0 0;
//                 }
//               }
//             }
//           }
//           &.nav-profile {
//             display: none;
//           }
//           &.nav-category {
//             display: none;
//           }
//           &.nav-doc {
//             margin: 0;
//             i {
//               display: block;
//             }
//           }
//           .collapse {
//             display: none;
//           }
//           &.hover-open {
//               .nav-link {
//                 .menu-title {
//                display: flex;
//                  align-items: center;
//                   background: $icon-only-menu-bg-light;
//                   @at-root #{selector-append(".sidebar-dark", &)} {
//                       background: $sidebar-dark-menu-active-bg;
//                   }
//                   padding: 0.5rem 1.4rem;
//                   left: $sidebar-width-icon;
//                   position: absolute;
//                   text-align: left;
//                   top: 0;
//                   bottom: 0;
//                   width: $icon-only-collapse-width;
//                   z-index: 1;
//                   line-height: 1.8;
//                   @at-root #{selector-append(".rtl", &)} {
//                     left: auto;
//                     right: $sidebar-width-icon;
//                     text-align: left;
//                   }
//                   @at-root #{selector-append(".sidebar-dark", &)} {
//                     color: $white;
//                   }
//                   &:after {
//                     display: none;
//                   }
//                 }
//               }
//               .collapse,
//               .collapsing {
//                 display: block;
//                 background: $icon-only-menu-bg-light;
//                border-radius:0 0 5px 0;
//                 @at-root #{selector-append(".sidebar-dark", &)} {
//                     background: $sidebar-dark-bg;
//                 }
//                 position: absolute;
//                 left: $sidebar-width-icon;
//                 width: $icon-only-collapse-width;
//                 @at-root #{selector-append(".rtl", &)} {
//                     left: auto;
//                     right:$sidebar-width-icon;
//                   border-radius:0 0 0 5px;
//                 }
//               }
//             }
//         }
//         &.sub-menu {
//           padding: $sidebar-icon-only-submenu-padding;
//           .nav-item {
//             .nav-link {
//               text-align: left;
//               padding-left: 20px;
//             }
//           }
//           @at-root #{selector-append(".rtl", &)} {
//             .nav-item {
//               margin-right: auto;
//               margin-left: 0;
//               .nav-link {
//                 text-align: right;
//                 &:before{
//                   left: 0;
//                   right: unset;
//                 }
//               }
//             }
//           }
//         }
//       }
//       .sidebar-actions {
//         display: none;
//       }
//     }

//     .main-panel {
//       width: calc(100% - #{$sidebar-width-icon});
//     }
//   }
// }

// @media (min-width: 992px){
//   span.menu-title {
// display: none !important;
// }
// }

@media (min-width: 991px){
.sidebar-mini .sidebar, .sidebar-mini .sidebar .sidebar-wrapper {
    width: 80px;
}
}
// @media (min-width: 991px){
//  span.menu-title {
//       display: none !important;
//   }
//   }

.sidebar-mini {
  span.menu-title, .caret {
    display: none !important;
  }
  .sidebar .nav li > a{
    padding: 7px 12px !important;
  }
   
  i.menu-icon {
    padding-right: 10px !important;
  }
}

.sidebar .sidebar-drawer:hover {
  span.menu-title, .caret {
    display: block !important;
  }
}

.sidebar .nav .active .nav-item.active .nav-link .menu-title {
  color: #b66dff;
}
.sidebar .nav .active .nav-item.active .nav-link i {
  color: #b66dff !important;
}