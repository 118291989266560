:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: "Be Vietnam", serif;
  height: 100%;
}

body,html {
  font-family: "Be Vietnam", serif;
}

.centered {
  margin-top: 2%;
  display: flex;
  justify-content: center;
}

.centered .btn {
  background-color: red;
  border-color: red;
}
.selectForm {
  width: 30% !important;
}
