.login-page .card-login.card-plain .form-control {
    border-color: #131313;
    color: #131313 !important;
    background-color: white !important;
}

/* .password-input {
    border-color: #131313;
    background-color: white !important;
    color: #131313 !important;
} */