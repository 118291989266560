/* Dropzone */
.dropzone {
    padding: 30px;
    height: 10vh;
    border: 5px dashed #7649bc;
    outline: none;
    cursor: pointer;
  }
  .dropzone-active {
    background-color: #f1e2ff;
    border: 5px solid #2fd7b5;
  }
  .dropzone-content {
    align-self: center;
    font-size: 24px;
  }
  
  /* Images */
  .file-list {
    margin: 20px 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  .file-item {
    max-width: calc(15% - 10px);
    max-height: 400px;
    border: 1px solid #a59c9c;
    border-radius: 4px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: move;
    transition: all 0.2s linear;
  }

  .file-item .btn-sm {
    font-size: 0.5em;
    border-radius: 3px;
    padding: 5px 3px;
}
  .file-img {
    width: 100%;
    height: 80%;
  }

  .card.card-plain img{
    border-radius: 0px !important;
  }
  
  .image-desc {
    line-height: 100%;
    padding-left: 5px;
    font-size: smaller;
    font-weight: 600;
  }
  /* Tablets */
  /* @media (max-width: 1000px) {
    .App {
      max-width: 600px;
    }
    .file-item {
      width: calc(50% - 22px);
      height: 200px;
    }
  } */
  
  /* Mobiles */
  /* @media (max-width: 640px) {
    .App {
      max-width: 100%;
      padding: 0 15px;
    }
    .file-item {
      width: calc(100% - 22px);
      height: 200px;
    }
  } */