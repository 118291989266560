.card{
  border-radius: $border-radius-extreme;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
  color: $card-black-color;
  margin-bottom: 20px;
  position: relative;
  border: 0 none;

  -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;

    .card-body{
        padding: 15px 15px 10px 15px;

        &.table-full-width{
            padding-left: 0;
            padding-right: 0;
        }
    }

    .card-header{
      &:not([data-background-color]){
        background-color: transparent;
      }
      padding: 15px 15px 0;
      border: 0;

      .card-title{
          margin-top: 10px;
      }
    }

    .map{
        border-radius: $border-radius-small;

        &.map-big{
          height: 400px;
        }
    }

    &[data-background-color="orange"]{
        background-color: $primary-color;

        .card-header{
            background-color: $primary-color;
        }

        .card-footer{
            .stats{
                color: $white-color;
            }
        }
    }

    &[data-background-color="red"]{
        background-color: $danger-color;
    }

    &[data-background-color="yellow"]{
        background-color: $warning-color;
    }

    &[data-background-color="blue"]{
        background-color: $info-color;
    }

    &[data-background-color="green"]{
        background-color: $success-color;
    }

    .image{
        overflow: hidden;
        height: 200px;
        position: relative;
    }

    .avatar{
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 15px;
    }

    .numbers {
      font-size: 2em;
    }

    .big-title {
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      padding-bottom: 15px;
    }

    label{
        font-size: $font-size-small;
        margin-bottom: 5px;
        color: $dark-gray;
    }

    .card-footer{
        background-color: transparent;
        border: 0;


        .stats{
            i{
                margin-right: 5px;
                position: relative;
                top: 0px;
                color: $default-color;
            }
        }

        .btn{
            margin: 0;
        }
    }

    &.card-plain{
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;


        .card-body{
            padding-left: 5px;
            padding-right: 5px;
        }

        img{
            border-radius: $border-radius-extreme;
        }
    }
}

/* Cards */

.card {
    border: 0;
    .card-body {
      padding: $card-padding-y $card-padding-x;
      @media(max-width: 767px) {
        padding: 2rem 2rem;
      }
      + .card-body {
        padding-top: 1rem;
      }
    }
    .card-title {
      color: $card-title-color;
      margin-bottom: .75rem;
      text-transform: capitalize;
      font-weight: $font-weight-medium;
      font-size: 1.125rem;
    }
    .card-subtitle {
      @extend .text-gray;
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;
    
    }
    .card-description {
      margin-bottom: 1.5rem;
      color: $card-description-color;
    }
    &.card-outline-success {
          border: 1px solid theme-color("success");
      }
      &.card-outline-primary {
          border: 1px solid theme-color("primary");
      }
      &.card-outline-warning {
          border: 1px solid theme-color("warning");
      }
      &.card-outline-danger {
          border: 1px solid theme-color("danger");
      }
      &.card-rounded {
          border-radius: 5px;
      }
  
    &.card-faded {
      background: #b5b0b2;
      border-color: #b5b0b2;
    }
    &.card-circle-progress {
      color: $white;
      text-align: center;
    }
    &.card-img-holder {
      position: relative;
      .card-img-absolute {
        position: absolute;
        top:0;
        right: 0;
        height: 100%;
      }
    }
  }
  
//   @each $color, $value in $theme-colors {
//     .card-inverse-#{$color} {
//       @include card-inverse-variant(rgba(theme-color($color), .2), theme-color-level($color, 1), theme-color-level($color, 3));
//     }
//   }
  