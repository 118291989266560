@media screen and (min-width: 0px) and (max-width: 600px) {
    .mobile-content { display: block; }  /* show it on small screens */
    .desktop-content { display: none; }
  }
  
  @media screen and (min-width: 601px) {
    .desktop-content { display: block; }   
    .mobile-content { display: none; }/* hide it elsewhere */
  }
  .shaded-background{
      background-color: #cecccc;
  }