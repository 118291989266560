.linkbutton {
    margin-left: 15px;
    display: inline-block;
    /* the default for span */
    /* width: 150px; */
    background-color: #8490f7;
    padding: 0px 10px;

}

.linkbutton:visited {
    text-decoration: none;
  }
  
  .linkbutton:hover {
    background-color: #910e0e;
    color: #ffff;
  }
  
  .linkbutton:active {
    text-decoration: underline;
  }

  .card_label {
    display: inline-block;
    /* the default for span */
    /* width: 150px; */
    background-color: #dfdfdf;
    padding: 0px 10px;

}

.card_label:visited {
    text-decoration: none;
  }
  
  .card_label:hover {
    background-color: #910e0e;
    color: #ffff;
  }
  
  .card_label:active {
    text-decoration: underline;
  }